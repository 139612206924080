<template>
    <div>
        <myHeader class="my-header"></myHeader>


        <div class="demo-collapse">
            <div class="page-title">
                Frequently Asked Questions
            </div>

            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="1. What are dietary supplements?" name="1">
                    <div>
                        Dietary supplements, per their title are products designed to be an addition to help achieve a
                        better balanced diet, typically containing vitamins, minerals, herbs, or other botanicals.
                    </div>
                </el-collapse-item>
                <el-collapse-item title="2. Are dietary supplements safe?" name="2">
                    <div>
                        Yes, dietary supplements are generally safe when used as directed and if they have been approved
                        by relevant health authorities. VESITIN’s supplements are made in FDA approved facilities and
                        hold several quality and safety certifications. <br><br> However, it’s important to consult
                        with a healthcare provider before starting any supplement,
                        especially for individuals with health conditions or those taking other medications, as
                        supplements can interact with medicines and may not be suitable for everyone.
                    </div>
                </el-collapse-item>
                <el-collapse-item title="3. How do I know which supplements to take?" name="3">
                    <div>
                        It is best to consult with a healthcare professional or a registered dietitian to determine
                        which supplements may be appropriate for you based on your individual needs and health status.
                        Generally, VESITIN’s supplement are best for adults with average good health, and target desired
                        areas to be improved.
                    </div>
                </el-collapse-item>
                <el-collapse-item title="4. How should I take dietary supplements?" name="4">
                    <div>
                        The recommended dosage and method of taking dietary supplements may vary depending on the
                        product. Always read the label and follow the instructions provided on the bottle or within the
                        package.
                    </div>
                </el-collapse-item>

                <el-collapse-item title="5. Are dietary supplements regulated by the FDA?" name="5">
                    <div>
                        The FDA oversees both prescription and over-the-counter medications, but not dietary
                        supplements.
                        <br><br>
                        That said, our natural, 100% drug-free supplements are manufactured in the USA in an
                        FDA-certified facility that adheres to the requirements of the United States Food and Drug
                        Administration (FDA) and uses Current Good Manufacturing Practices (CGMPs) to ensure its safety.
                    </div>
                </el-collapse-item>

                <el-collapse-item title="6. products recommended for all types of people?" name="6">
                    <div>
                        We do not recommend VESITIN products to anyone under the age of 18. Also, while we have no
                        reason to believe it’s harmful, there have not been enough studies for us to say VESITIN
                        products are suitable during pregnancy. Therefore, we recommend skipping VESITIN products while
                        pregnant, trying to conceive, or breastfeeding. If you have a medical condition or are taking
                        medications (especially anticoagulants and blood-thinning drugs), please consult with your
                        physician before taking VESITIN products.
                    </div>
                </el-collapse-item>

                <el-collapse-item title="7. Where can I buy VESITIN products?" name="7">
                    <div>
                        We’re dedicated to making VESITIN easily accessible. To ensure you receive the best products and
                        service, VESITIN is available exclusively on VESITIN.com, Amazon, TikTok on VESITIN USA
                        (@VESITIN.official) and VESITIN – Health (@VESITIN_4u), and Walmart from our official brand.
                        <br><br>
                        Counterfeit VESITIN products by unauthorized sellers have been found. These products are unsafe
                        and should not be used. Always purchase VESITIN products from the listed sources above.
                        <br><br>
                        If you need help confirming or reporting a seller, please email us at info@VESITIN.com.
                    </div>
                </el-collapse-item>

                <el-collapse-item title="8. Can I return a supplement if I am not satisfied with it?" name="8">
                    <div>
                        Yes, your satisfaction is our priority. VESITIN products purchased on VESITIN.com have a 90 Days
                        Money Back Guarantee policy. If you are not satisfied with your product, please follow the
                        Return Request steps here.
                    </div>
                </el-collapse-item>

                <el-collapse-item title="9. Can I take dietary supplements with other medications?" name="9">
                    <div>
                        It is important to speak with a healthcare professional before taking any new supplement if you
                        are currently taking other medications, as there may be potential interactions. However, VESITIN
                        supplements can be taken together, for example you could take two capsules of Immune and one
                        capsule of Hair per day.
                    </div>
                </el-collapse-item>

                <el-collapse-item title="10. How should I store my supplements?" name="10">
                    <div>
                        VESITIN products come in amber glass bottles to protect from light absorption and help preserve
                        the nutritional integrity of the supplements.
                        <br><br>
                        The product’s ingredients are natural extracts. It should be kept tightly sealed, stored in an
                        environment away from direct sunlight, and kept well-ventilated and dry to prevent moisture and
                        oxidation of the ingredients, which can affect efficacy.
                    </div>

                </el-collapse-item>

                <el-collapse-item title="11. Do VESITIN products contain any drugs?" name="11">
                    <div>
                        No, VESITIN products are 100% drug-free.
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

        <myFooter></myFooter>
    </div>

</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';

export default {
    data() {
        return {
            activeNames: ''
        }
    },
    components: {
        myFooter,
        myHeader
    },

    methods: {
        handleChange: (val) => {
            console.log(val)
        }
    }
}

</script>

<style>
.demo-collapse {
    margin-top: 126px;
    padding: 0 20vw;
}

.page-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 100px;
    height: 100px;
}


@media (max-width: 768px) {
  .page-title {
    font-size: 18px !important;
  }

  .demo-collapse{
    padding: 2vw !important;
  }
}
</style>