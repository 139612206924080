<template>
  <div class="footer-box">
    <myHeader class="my-header"></myHeader>

    <div class="art-container">


      <div class="art-list">

        <div class="art-item" v-for="(item, index) in list" :key="index" @click="navigateTo">
          <img class="item-p-img item-p-cell" :src="require('@/assets/' + item.img)" alt="">
          <div class="item-p-name item-p-cell">{{ item.name }}</div>
          <div class="item-p-price item-p-cell">￥{{ item.price }}</div>
          <div class="item-p-spec item-p-cell">{{ item.spec }}</div>
          <div class="item-p-pattern item-p-cell">{{ item.pattern }}</div>
          <div class="item-p-place item-p-cell">{{ item.place }}</div>
        </div>


      </div>
    </div>
    <myFooter></myFooter>
  </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'HomePage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {
      list: [
        { img: 'distr/P01.png', name: '脂肪酶浓缩胶囊', price: '380', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P02.png', name: '男性刺蒺藜片', price: '380', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P03.png', name: '清肠道胶囊', price: '380', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' }
      ]
    }
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },


    // navigateTo(e){
    //   this.$route.push({
    //     path:''
    //   })
    //   })
    // }
  }
}
</script>


<style lang="less">
.art-container {
  margin-top: 126px;

  .art-list {
    padding: 20px 5%;

    .art-item {
      display: flex;
      justify-content: space-around;

      .item-p-cell {
        line-height: 200px;
      }

      .item-p-img {
        width: 200px;
        height: 200px;
      }

      .item-p-name{
        width: 120px;
        text-align: center;
        font-weight: 700;
      }
    }

    .art-item:hover {
      background-color: rgb(233, 244, 245);
    }
  }
}

@media (max-width: 768px) {
  .art-list {
    font-size: 10px;
    padding: 0 5%;

    .art-item {
      display: flex;
      justify-content: space-around;
      flex-wrap: nowrap;
      overflow: scroll;

      .item-p-cell {
        line-height: 100px !important;
      }

      .item-p-img {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }
}
</style>