<template>
    <div class="footer-box">
        <myHeader class="my-header"></myHeader>


        <div>
            <div class="model-1">
                <div class="model-1-title">Best Sellers</div>


                <div class="p-list">

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P01.png" alt="vesitin">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P02.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P03.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>


                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P01.png" alt="vesitin">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P02.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P03.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>
                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P01.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>


                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P02.png" alt="vesitin">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P03.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                    <div class="p-item">
                        <img class="p-item-img" src="../assets/distr/P01.png" alt="">
                        <h3>Hair + </h3>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

                        <div class="p-item-btn">view product</div>
                    </div>

                </div>
            </div>
        </div>


        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'HomePage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {

        }
    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.model-1 {
    background-color: #F2F2F2;

    .model-1-title {
        padding-top: 60px;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        margin: 10px 0 20px 0;
        line-height: 50px;
    }

    .p-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 30px;

        .p-item {
            // height: 484px;
            background: white;
            margin: 5px;
            padding: 10px;
            font-size: 18px;

            .p-item-yj {
                text-decoration: line-through;
            }

            .p-item-xj {
                font-weight: 700;
                margin-left: 10px;
            }


            .p-item-img {
                width: 318px;
                height: 318px;
            }

            .p-item-btn {
                background-color: black;
                height: 39px;
                color: white;
                text-align: center;
                line-height: 39px;
                border-radius: 5px;
                margin: 10px 0;
            }
        }
    }
}


@media (max-width: 768px) {
    .banner {
        width: 100vw;
    }


    .p-list {
        padding: 0;
        flex-wrap: wrap;
    }

    .p-item-img {
        width: 90vw !important;
        height: auto !important;
    }

    .footer {
        height: 138px;
    }
}
</style>