<template>
    <div class="footer-box">
        <myHeader class="my-header"></myHeader>

        <div class="top-img-box">
            <img class="top-img" src="../assets/About-Us-Header.jpg" alt="">
            <div class="top-img-mask"></div>
        </div>

        <div class="aboutus-content">
            <div>
                VESITIN's Synergy
            </div>

            <div>
                VESITIN’s synergistic approach to supplements is rooted in the meticulous selection of quality
                ingredients that work in concert to amplify your health benefits. By harnessing the collective power of
                these components, our solutions transcend ordinary supplementation. Each ingredient is chosen not only
                for its individual efficacy but also for its ability to enhance the effects of others. This strategic
                combination results in a compounded impact on your wellness, delivering more than the sum of its parts
                and ensuring that every product from VESITIN is a step towards optimal health.
            </div>


            <div>

            </div>
        </div>


        <div class="honor">
            <div>
                <div class="honoe-title">Certifications</div>
                <div class="honor-box">
                    <div>
                        <img class="cer-img" src="../assets/FDA.png" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h02.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h03.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h04.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h05.jpg" alt="">
                    </div>
                </div>
            </div>

            <div>
                <div class="honoe-title">Safely Formulated</div>
                <div class="honor-box">
                    <div>
                        <img class="cer-img" src="../assets/h2-1_01.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_02.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_03.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_04.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_05.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_06.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'HomePage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {
            
        }
    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>


<style lang="less" scoped>
.footer-box {
    padding-top: 126px;
}

.top-img-box {


    .top-img {
        width: 100%;
    }

    .top-img ::before {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.aboutus-content {
    padding: 0 20vw;
}



.honor {
    .honoe-title {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        padding: 20px 0;
    }

    .honor-box {
        margin: 10px 0;
        display: flex;
        padding: 0 10vw;
        justify-content: space-around;
        flex-wrap: wrap;

        .cer-img {
            width: 117px;
            height: auto;
        }
    }

}
</style>