<template>
    <div class="header-box">
        <div class="header">
            <div class="header-left">
                <div>Hot Line：4008873528</div>
                &nbsp;&nbsp;&nbsp;
                <!-- <div class="phone">15851181515</div> -->
            </div>

            <div class="contact-box">
                <div class="iconfont icon-facebook"></div>
                <div class="iconfont icon-Youtube"></div>
                <div class="iconfont icon-instagram-line"></div>
                <div class="iconfont icon-douyin-"></div>
            </div>


        </div>

        <div class="nav">
            <div class="meau-box">
                <div class="iconfont icon-ego-menu" @click="meauSwitch"></div>

                <div class="meau-list" v-show="meauShow">
                    <div class="meau-item" @click="navigatorTo" data-url="/">Home</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/AboutUs">About Us</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/Shop">Shop</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/FAQs">FAQs</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/ContactUs">Contact Us</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/Distribution">分销(大陆地区)</div>
                </div>

            </div>

            <div class="logo-box">
                <img class="logo" @click="navigatorTo" data-url="/HomePage" src="../assets/vesitinLogo.png" alt="">
            </div>

            <div class="nav-list">
                <div @click="navigatorTo" data-url="/"> Home&nbsp; | &nbsp;&nbsp;</div>
                <div @click="navigatorTo" data-url="/AboutUs"> About Us&nbsp; | &nbsp;&nbsp;</div>
                <div @click="navigatorTo" data-url="/Shop"> Shop&nbsp; | &nbsp;&nbsp;</div>
                <div @click="navigatorTo" data-url="/FAQs"> FAQs&nbsp; | &nbsp;&nbsp;</div>
                <div @click="navigatorTo" data-url="/ContactUs"> Contact Us | &nbsp;&nbsp;</div>
                <div class="meau-item" @click="navigatorTo" data-url="/Distribution">分销(大陆地区)</div>
            </div>
            <div class="login-box" @click="navigatorTo" data-url="/Login">Login</div>
        </div>
    </div>

</template>


<script>
export default {
    name: 'myHeader',
    data() {
        return {
            meauShow: false,
        }
    },

    methods: {
        meauSwitch() {


            console.log(this.meauShow)

            this.meauShow = !this.meauShow;
        },

        navigatorTo(e) {
            this.$router.push(e.target.dataset.url)
        },
    }
}

</script>


<style lang="less" scoped>
.header {
    padding: 0 30px;
    height: 50px;
    background-color: black;
    color: white;
    line-height: 50px;
    font-size: 16px;
    display: flex;
    font-family: Outfit, sans-serif;
    justify-content: space-between;

    .header-left {
        display: flex;
    }

    .contact-box {
        display: flex;
        font-size: 124px;

        .iconfont {
            font-size: 20px;
            padding: 0 10px;
            line-height: 50px;
        }
    }

    .iconfont {
        font-size: 10px;
        color: white;
    }
}

.nav {
    padding: 0 20px;
    height: 76px;
    display: flex;
    justify-content: space-between;


    .meau-box {
        display: none;
    }


    .icon-ego-menu {
        line-height: 76px;

    }

    .meau-list {
        z-index: 9999;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: white;
        /* display: none; */

        .meau-item {
            line-height: 40px;
            padding: 0 20px;

            .meau-item：hover {
                cursor: hand
            }
        }


    }

    .login-box {
        line-height: 76px;
        font-size: 18px;
        font-weight: 700;
    }

    .logo-box {


        .logo {
            padding-top: 6px;
            width: 150px;
        }
    }

    .nav-list {
        display: flex;
        width: 50vw;
        line-height: 76px;
    }

}

@media (max-width: 768px) {

    .phone {
        display: none !important;
    }

    .nav-list {
        display: none !important;
    }

    .meau-box {
        display: block !important;
    }
}
</style>