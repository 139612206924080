<template>
  <div class="page">

    <myHeader class="my-header"></myHeader>


    <div class="banner-box">
      <img class="banner" src="../assets/banner1.jpg" alt="">
    </div>

    <div class="model-1">
      <div class="model-1-title">Best Sellers</div>


      <div class="p-list">

        <div class="p-item">
          <img class="p-item-img" src="../assets/distr/P01.png" alt="vesitin">
          <h3>Lipase Fat Burnuing</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

        <div class="p-item">
          <img class="p-item-img" src="../assets/distr/P02.png" alt="">
          <h3>TRIBULUS plus ZINC </h3>
          <div><span class="p-item-yj">$109.00</span><span class="p-item-xj">$96.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

        <div class="p-item">
          <img class="p-item-img" src="../assets/distr/P03.png" alt="">
          <h3>Intestinal GUT SUPPORT</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

        <div class="p-item">
          <img class="p-item-img" src="../assets/p04.png" alt="">
          <h3>Intestinal GUT SUPPORT</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>


        <div class="p-item">
          <img class="p-item-img" src="../assets/p03.png" alt="">
          <h3>Intestinal GUT SUPPORT</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>



      </div>
    </div>


    <!-- <div class="model-1">
      <div class="model-1-title">Best Sellers</div>


      <div class="p-list">

        <div class="p-item">
          <img class="p-item-img" src="../assets/distr/P01.png" alt="vesitin">
          <h3>Lipase Fat Burnuing</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

        <div class="p-item">
          <img class="p-item-img" src="../assets/distr/P02.png" alt="">
          <h3>TRIBULUS plus ZINC </h3>
          <div><span class="p-item-yj">$109.00</span><span class="p-item-xj">$96.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

        <div class="p-item">
          <img class="p-item-img" src="../assets/distr/P03.png" alt="">
          <h3>Intestinal GUT SUPPORT</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>

        <div class="p-item">
          <img class="p-item-img" src="../assets/p04.png" alt="">
          <h3>Intestinal GUT SUPPORT</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>


        <div class="p-item">
          <img class="p-item-img" src="../assets/p03.png" alt="">
          <h3>Intestinal GUT SUPPORT</h3>
          <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>

          <div class="p-item-btn">view product</div>
        </div>



      </div>
    </div> -->

    <div class="honor">
      <div>
        <div class="honoe-title">Certifications</div>
        <div class="honor-box">
          <div>
            <img class="cer-img" src="../assets/FDA.png" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h02.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h03.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h04.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h05.jpg" alt="">
          </div>
        </div>
      </div>

      <div>
        <div class="honoe-title">Safely Formulated</div>
        <div class="honor-box">
          <div>
            <img class="cer-img" src="../assets/h2-1_01.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h2-1_02.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h2-1_03.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h2-1_04.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h2-1_05.jpg" alt="">
          </div>
          <div>
            <img class="cer-img" src="../assets/h2-1_06.jpg" alt="">
          </div>
        </div>
      </div>
    </div>






    <myFooter></myFooter>



    <div class="back-box" @click="backTop">
      <div class="iconfont icon-xiangshang1"></div>
    </div>


  </div>
</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'HomePage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {

    }
  },
  mounted() {
    console.log(this.$axios);
    // this.$axios({
    //   method: 'get',
    //   url: '/api/feedback/feedbackList',
    //   data: {}
    // }).then(res => {
    //   console.log(res);
    // })
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped lang="less">
.banner-box {
  text-align: center;
  background-color: #e9e9eb;
  margin-top: 126px;
}

.model-1 {
  background-color: #F2F2F2;

  .model-1-title {
    padding-top: 60px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin: 10px 0 20px 0;
    line-height: 50px;
  }

  .p-list {
    display: flex;
    justify-content: space-around;
    padding-bottom: 30px;

    .p-item {
      // height: 484px;
      background: white;
      margin: 5px;
      padding: 10px;
      font-size: 18px;

      .p-item-yj {
        text-decoration: line-through;
      }

      .p-item-xj {
        font-weight: 700;
        margin-left: 10px;
      }


      .p-item-img {
        width: 318px;
        height: 318px;
      }

      .p-item-btn {
        background-color: black;
        height: 39px;
        color: white;
        text-align: center;
        line-height: 39px;
        border-radius: 5px;
        margin: 10px 0;
      }
    }
  }
}



.honor {
  .honoe-title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    padding: 20px 0;
  }

  .honor-box {
    margin: 10px 0;
    display: flex;
    padding: 0 10vw;
    justify-content: space-around;
    flex-wrap: wrap;

    .cer-img {
      width: 117px;
      height: auto;
    }
  }

}



.back-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 5px;
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .banner {
    width: 100vw;
  }


  .p-list {
    padding: 0;
    flex-wrap: wrap;
  }

  .p-item-img {
    width: 90vw !important;
    height: auto !important;
  }

  .footer {
    height: 138px;
  }
}
</style>
