<template>
    <div class="footer-box">
      <myHeader class="my-header"></myHeader>
  
      <div class="login-container">
  
      </div>
      <myFooter></myFooter>
    </div>
  
  
  </template>
  
  <script>
  import myFooter from '../components/myFooter.vue';
  import myHeader from '../components/myHeader.vue';
  
  
  export default {
    name: 'LoginPage',
    components: {
      myFooter,
      myHeader
    },
    data() {
      return {
  
      }
    },
    methods: {
      backTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
  </script>
  
  <style>
  .login-container {
    margin-top: 126px;
  }
  </style>
  